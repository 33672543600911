import React from 'react';

import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import NoteIcon from '@mui/icons-material/Note';
import Assignment from '@mui/icons-material/Assignment';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Edit from '@mui/icons-material/Edit';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { AppState } from '../states/appState';
import { EditStateDialog } from './EditStateDialog';
import { EditStateAPIParam } from '../actions/editingActsAction';

import { EditingState, EditingAct, EditingActs } from '../states/editingState';
import { EditingActions } from '../containers/EditingContainer';

type EditingActProps = AppState & EditingState & EditingActions;

const EDIT_TYPE = [
  '新規制定',
  '一部改正',
  '全部改正',
  '廃止',
];

interface SelectedAct {
  actId: string;
  editingId: string;
};

export interface DownloadDocument {
  allact?: string;
  revision?: string;
  newold?: string;
};

export const Editing: React.FC<EditingActProps> = (props) => {

  const [selectedAct, setSelectedAct] = React.useState<null | SelectedAct>(null);
  // 編集状態
  const [openEditStateDialog, setOpenEditStateDialog] = React.useState(false);
  const handleOpenEditStateDialog = () => setOpenEditStateDialog(true);
  const handleCloseEditStateDialog = () => setOpenEditStateDialog(false);
  const handleEditStateDialog = (editingId: string, actId: string, existEditState: string,) => {
    setSelectedAct({ actId: actId, editingId: editingId });
    // 承認済み等の(id=1～9)は除去
    setEditStateList(props.editStateList.filter((v) => Number(v.id) >= 10 || Number(v.id) === 0));
    setEditStateValue(existEditState);
    handleOpenEditStateDialog();
  };
  const [editStateList, setEditStateList] = React.useState<any>(null);
  const [editStateValue, setEditStateValue] = React.useState<string>('');
  const handleEditStateRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditStateValue((event.target as HTMLInputElement).value);
  };
  const okEditStateDialog = () => {
    handleCloseEditStateDialog();
    if (selectedAct) {
      const apiParam: EditStateAPIParam = {
        actId: selectedAct.actId,
        editingId: selectedAct.editingId,
        accountId: props.accountid,
        idid: props.idid,
        id: props.id,
        editState: editStateValue
      }
      props.setLoading(true);
      props.putEditStateAsync(apiParam);
    } else {
      console.error('selectedAct null');
    }
  };

  // 編集画面OPEN
  const openEditor = (editingId: string, actId: string, editType: string, stepId: string) => {
    const pureEditingId = editingId.substring(editingId.indexOf('#') + 1);
    const query = `?actId=${actId}&editingId=${pureEditingId}&editType=${editType}&stepId=${stepId}`;
    const url = process.env.REACT_APP_EDITOR_URL ? process.env.REACT_APP_EDITOR_URL.replace('{hostname}', window.location.hostname) : process.env.REACT_APP_EDITOR_URL;
    window.open(url + query, 'editor');
  };
  // 個別表示OPEN
  const editingView = (editingId: string, actId: string, stepId: string) => {
    const query = `?type=${'editor'}&actId=${actId}&editingId=${editingId}&stepId=${stepId}&miekesi=true`;
    const url = process.env.REACT_APP_VIEWER_URL ? process.env.REACT_APP_VIEWER_URL.replace('{hostname}', window.location.hostname) : process.env.REACT_APP_VIEWER_URL;
    window.open(url + query, `viewer_${actId}_${editingId}_${stepId}`);
  };
  // 編集例規削除
  const removeDelete = (actId: string, editingId: string) => {
    props.setLoading(true);
    const pureEditingId = editingId.substring(editingId.indexOf('#') + 1);
    props.deleteEditingActAsync(props.accountid, props.idid, actId, pureEditingId, props.id);
  };

  const [removeTarget, setRemoveTarget] = React.useState<null | SelectedAct>(null);
  const removeConfirm = (actId: string, editingId: string) => {
    setRemoveTarget({ actId: actId, editingId: editingId });
    setSubDialog(true);
  };
  const [subDialog, setSubDialog] = React.useState(false);
  const openSubDialog = Boolean(subDialog);
  const closeSubDialog = () => {
    setSubDialog(false);
    setRemoveTarget(null);
  }
  const okSubDialog = () => {
    closeSubDialog();
    if (removeTarget) {
      removeDelete(removeTarget.actId, removeTarget.editingId);
    }
    setRemoveTarget(null);
  };

  const getTitle = (act: EditingAct) => {
    if (act.newTitle) {
      return '(' + act.editingId + ')' + act.newTitle + '[旧:' + act.title + ']';
    } else {
      return '(' + act.editingId + ')' + act.title;
    }
  };


  const getStateText = (param: string, rejection: boolean) => {
    const r = props.editStateList.filter((v) => v.id === param)
    return r.length === 1 ? (r[0].id === '10' && rejection) ? '差し戻し' : r[0].name : '？？？？';
  };

  const createActList = (b: EditingActs) => {
    return b.editingActTables.map((a, index) => {
      return (
        <div key={'li' + index + a.actId}>
          <ListItem button onClick={() => editingView(a.editingId, a.actId, a.stepId)}>
            <ListItemIcon sx={{
              color: (theme) => theme.palette.primary.main,
            }}>
              <NoteIcon />
            </ListItemIcon>
            <ListItemText key={index + a.actId} primary={getTitle(a)} secondary={
              <>
                <Typography variant="subtitle1" component="span">番号：</Typography>
                <Typography variant="body1" component="span">{a.actNum}</Typography>
                <br />
                <Typography variant="subtitle1" component="span">改正番号：</Typography>
                <Typography variant="body1" component="span">{a.editActNum}</Typography>
                <br />
                <Typography variant="subtitle1" component="span">編集種別：</Typography>
                <Typography variant="body1" component="span">{EDIT_TYPE[Number.parseInt(a.editType)]}</Typography>
                <br />
                <Typography variant="subtitle1" component="span">状態：</Typography>
                <Typography variant="body1" component="span">{getStateText(a.editState, a.rejection)}</Typography>
                <br />
                <Typography variant="subtitle1" component="span">更新日時：</Typography>
                <Typography variant="body1" component="span">{a.updateDate}</Typography>
              </>
            } />
            <ListItemSecondaryAction>
              <Tooltip title="状態を変更する">
                <IconButton sx={{
                  color: (theme) => theme.palette.secondary.main,
                  marginRight: 1,
                }} edge="end" aria-label="edit_state" onClick={() => handleEditStateDialog(a.editingId, a.actId, a.editState)}>
                  <Assignment />
                </IconButton>
              </Tooltip>
              <Tooltip title="編集を再開する">
                <IconButton sx={{
                  color: (theme) => theme.palette.secondary.main,
                  marginRight: 1,
                }} edge="end" aria-label="editor" onClick={() => openEditor(a.editingId, a.actId, a.editType, a.stepId)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="編集データを削除する">
                <IconButton sx={{
                  color: (theme) => theme.palette.secondary.main,
                  marginRight: 1,
                }} edge="end" aria-label="delete" onClick={() => removeConfirm(a.actId, a.editingId)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
        </div>
      );
    });
  };

  const createInitArray = (len) => {
    const a = Array(len === 0 ? 0 : len - 1).fill(false);
    a.unshift(true);
    return a;
  };
  const [opens, setOpens] = React.useState(createInitArray(props.editingActs.length));
  const handleClick = (index: number) => {
    const copy = opens.slice();
    copy[index] = !copy[index];
    setOpens(copy);
  };
  const createList = () => {
    if (props.editingActs.length < 1) {
      return (
        <ListItem key={'item0'}>
          <ListItemText primary={'編集中の文書がありません'} />
        </ListItem>);
    }
    return props.editingActs.map((b, index) => {
      return (
        <div key={index}>
          <ListItem key={'li' + index}>
            {opens[index] ? <ExpandLess onClick={() => handleClick(index)} /> : <ExpandMore onClick={() => handleClick(index)} />}
            <ListItemText key={b.id} primary={b.id + 'さんの編集中データ'} onClick={() => handleClick(index)} />
          </ListItem>
          <Collapse in={opens[index]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {createActList(b)}
            </List>
          </Collapse>
          <Divider />
        </div>
      )
    });
  }

  return (
    <>
      <Typography sx={{ margin: 2 }} variant="h5" gutterBottom>
        <Edit fontSize='large' color='primary' sx={{ marginBottom: 1, marginRight: 1, verticalAlign: 'middle' }} />
        編集を再開する
      </Typography>
      <Paper sx={{
        minWidth: 275,
        margin: 1,
        padding: 1,
        backgroundColor: (theme) => theme.palette.primary[50],
      }} elevation={3}>
        <List component="nav" aria-label="My editing acts">
          {createList()}
        </List>
      </Paper>
      <EditStateDialog
        open={openEditStateDialog}
        editStateValue={editStateValue}
        editStateList={editStateList}
        handleRadioChange={handleEditStateRadioChange}
        handleClose={handleCloseEditStateDialog}
        okEditStateDialog={okEditStateDialog}
      />
      <Dialog
        open={openSubDialog}
        keepMounted
        onClose={closeSubDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">削除確認</DialogTitle>
        <DialogContent>
          <Typography>削除した編集データは復元できませんが、削除してもよろしいですか？</Typography>
          <Typography>（復元できないことを理解した上で削除します）</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={okSubDialog} color="primary">
            削除する
          </Button>
          <Button onClick={closeSubDialog} color="primary">
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
